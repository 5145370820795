<template>
  <div>
    <el-timeline-item
      v-if="content"
      :icon="icon"
      :type="type"
      :color="color"
      size="large"
      :timestamp="formatDate(activity.createdOn)"
    >
      <div class="flex gap-4">
        <div v-html="formattedActivityText" class=""></div>
        <span
          v-if="displayDeletedQuoteyValidationActivity"
          v-on:click="expandQuoteValidationView"
        >
          <font-awesome-icon
            :icon="[
              'fal',
              !displayDeletedQuoteyValidationAExpanded
                ? 'chevron-right'
                : 'chevron-down'
            ]"
            class="text-blue-700 hover:text-blue-400 mr-2"
          />
        </span>
      </div>

      <div
        v-if="displayDeletedQuoteyValidationAExpanded"
        v-html="formatDeleteQuoteValidationDisplay"
      ></div>
    </el-timeline-item>
  </div>
</template>

<script>
import ActivitiesTimelineMixin from "@/components/ActivitiesView/ActivitiesTimelineMixin.vue";
export default {
  data() {
    return {
      displayDeletedQuoteyValidationActivity: false,
      displayDeletedQuoteyValidationAExpanded: false
    };
  },
  mixins: [ActivitiesTimelineMixin],
  props: {
    activity: {
      type: Object,
      required: true
    },
    quoteCountyValidation: {
      type: Object,
      required: false
    }
  },
  methods: {
    async generateReadableUpdateQuote() {
      let contentHtml = ``;
      for (let update of this.updates) {
        contentHtml = this.generateRegularActivityString(contentHtml, update);
      }
      this.setReadableUpdateProps(contentHtml);
    },
    expandQuoteValidationView() {
      this.displayDeletedQuoteyValidationAExpanded = !this
        .displayDeletedQuoteyValidationAExpanded;
    }
  },
  computed: {
    formatDeleteQuoteValidationDisplay() {
      let html = "";
      const entries = Object.entries(this.activity.data);

      for (const [key, value] of entries) {
        if (key === "quoteCountyValidationId") continue;
        html += `<p>${key}: ${value}</p>`;
      }
      return html;
    }
  },
  watch: {
    activity: {
      async handler() {
        this.content = "";
        this.icon = "";
        this.type = "";
        this.link = {};
        this.color = "";
        switch (this.activity.name) {
          case "ADD_QUOTE_COUNTY_VALIDATION_SUCCESS":
            this.generateReadableGeneric({
              content: `created a quote validation`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_QUOTE_COUNTY_VALIDATION_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to add an quote validation.",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_QUOTE_COUNTY_VALIDATION_SUCCESS":
            await this.generateReadableUpdateQuote();
            break;
          case "UPDATE_QUOTE_COUNTY_VALIDATION_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to update quote validation.",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_QUOTE_COUNTY_VALIDATION_SUCCESS":
            this.displayDeletedQuoteyValidationActivity = true;
            this.generateReadableGeneric({
              content: `deleted a quote validation`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_QUOTE_COUNTY_VALIDATION_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to delete quote validation`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
        }
      },
      immediate: true
    }
  }
};
</script>
