
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import QuoteCountyValidationsActivitiesTimelineItem from "./QuoteCountyValidationsActivitiesTimelineItem.vue";
interface IQuoteCountyValidationsActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "quoteCountyValidationsActivitiesView",
  components: { QuoteCountyValidationsActivitiesTimelineItem },
  mixins: [ActivitiesMixin],

  data(): IQuoteCountyValidationsActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `
            ADD_QUOTE_COUNTY_VALIDATION_FAILURE,
            ADD_QUOTE_COUNTY_VALIDATION_SUCCESS,
            UPDATE_QUOTE_COUNTY_VALIDATION_FAILURE,
            UPDATE_QUOTE_COUNTY_VALIDATION_SUCCESS,
            DELETE_QUOTE_COUNTY_VALIDATION_SUCCESS,
            DELETE_QUOTE_COUNTY_VALIDATION_FAILURE
            `.replace(/\s/g, ""),
        activityType: "QuoteCountyValidationsActivity"
      };
    }
  }
});
